import { createStore } from 'vuex'
import mutations from './mutations';
import actions from './actions';
import getters from './getters'

const state = {
    nowTheme:'default-theme', //当前主题
    themes:['default', 'light'], //所有主题
    appId:"CP",
    companyList:[],
    currentCompany:{},
    /**
     * 用户信息
     */
    userInfo:{},
    userList:[]
}
export default createStore({
    state,
    mutations,
    actions,
    getters,
    modules: {
    }
})
