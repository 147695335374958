//计算闰年
export function isLeap(year){
    return year % 4 == 0 && year % 100 != 0 || year % 400 ==0;
}

//定义方法，返回指定年月天数
export function getMonthDays(year, month){
    var days = 0;
    switch (month){
        case 1:
        case 3:
        case 5:
        case 7:
        case 8:
        case 10:
        case 12:
            days = 31;
            break;
        case 4:
        case 6:
        case 9:
        case 11:
            days = 30;
            break;
        case 2:
            //闰年 29天
            //平年28天
            days = isLeap(year) ? 29 : 28;
            break;
        default:
            days = 0;
            break;
    }
    return days;
}
