//这个跟组件的computed差不多，接收state作为参数，不接受组件传参数。需要return值。
const getters = {
    isLogin(state, getters){
        return getters.getUserInfo && getters.getUserInfo.token ? true :false;
    },
    getUserInfo(state){
        let ret = {};
        if(JSON.stringify(state.userInfo) === '{}'){
            if(window.localStorage.length > 0){
                let userStr = window.localStorage.getItem("userInfo");
                // console.log(userStr === 'undefined');
                if(userStr !== 'undefined' && userStr){
                    let userObj = JSON.parse(userStr);
                    if(userObj.tokenUser){
                        ret.coId = parseInt(userObj.tokenUser.coId);
                        ret.userId = parseInt(userObj.tokenUser.userId);
                        ret.userName = userObj.tokenUser.userName;
                        ret.userNo = userObj.tokenUser.userNo;
                    }
                    ret.token = userObj.token;
                }
            }
        }
        else{
            // ret = state.userInfo;
            if(state.userInfo){
                ret.coId = parseInt(state.userInfo.tokenUser.coId);
                ret.userId = parseInt(state.userInfo.tokenUser.userId);
                ret.userName = state.userInfo.tokenUser.userName;
                ret.userNo = state.userInfo.tokenUser.userNo;
                ret.token = state.userInfo.token;
            }
        }
        return ret;
    }
}
export default getters;