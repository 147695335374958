import {axiosFetch, axiosGet} from '../axiosFetch'
// import qs from 'Qs'

export function register(data){
    return axiosFetch({
        url:'/user/register',
        method:'post',
        data:data
    })
}
export function login(data){
    return axiosFetch({
        url:'/user/login',
        method:'post',
        // headers: {'Content-Type':'application/x-www-form-urlencoded;charset=utf-8'},
        // data:Qs.stringify(data)
        headers:{'Content-Type':'application/json;charset=utf-8'},
        data:JSON.stringify(data)
    })
}
export function logout(){
    return axiosFetch({
        url:'/user/logout',
        method:'post',
    })
}

export function getUsers(){
    return axiosFetch({
        url:'/user/list'
    })
}

