import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-dd970852"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "birth-chart"
};
const _hoisted_2 = {
  id: "canvasId",
  ref: "canvasRef",
  width: "900",
  height: "900",
  style: {
    "width": "600px",
    "height": "600px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_row = _resolveComponent("el-row");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, null, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 24
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: $setup.birthdayInput,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.birthdayInput = $event),
        rows: 2
      }, {
        append: _withCtx(() => [_createVNode(_component_el_button, {
          type: "primary",
          onClick: $setup.onSubmit
        }, {
          default: _withCtx(() => [_createTextVNode("?")]),
          _: 1
        }, 8, ["onClick"])]),
        _: 1
      }, 8, ["modelValue"])]),
      _: 1
    })]),
    _: 1
  }), _createElementVNode("canvas", _hoisted_2, null, 512)]);
}