import { ref, onMounted, onUpdated } from "vue";
import { getMonthDays, isLeap } from "@/assets/js/commonFunctions";
export default {
  name: "Main",
  setup() {
    let curr_time = ref(""); // '2021-12-08 12:23:00';
    let curr_year = ref("");
    let remain_days = ref("");
    let passDays = ref(1);
    let passWidth = ref("");
    let remainWidth = ref("");
    const currYear = () => {
      curr_year.value = new Date().getFullYear();
    };
    const currTime = () => {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let hour = date.getHours();
      let minute = date.getMinutes();
      let second = date.getSeconds();
      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;
      hour = hour < 10 ? "0" + hour : hour;
      minute = minute < 10 ? "0" + minute : minute;
      second = second < 10 ? "0" + second : second;
      // let Timer = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
      let Timer = year + '-' + month + '-' + day + ' ' + hour + ':' + minute;
      //在页面上插入日期
      curr_time.value = Timer;
      setTimeout(function () {
        currTime();
      }, 1000 * 60);
    };
    const daysRemain = () => {
      //今年有多少天
      let today = new Date();
      let year = today.getFullYear(); //获取完整的年份(4位,1970)
      let month = today.getMonth() + 1; //获取当前月份(0-11,0代表1月)
      let day = today.getDate(); //获取当前日(1-31)
      let sum = 0;
      //累计year这一年【1， month）整月的天数
      for (let i = 1; i < month; i++) {
        sum += getMonthDays(year, i);
      }
      //最后累加year年month月当月的天数
      sum += day;

      //已经过了多少天
      return {
        year_days: isLeap(year) ? 366 : 365,
        passed_days: sum
      };
    };
    const daysRemainCalc = () => {
      let ret = daysRemain();
      let total_width = 1000;
      remain_days.value = ret.year_days - ret.passed_days;
      passDays.value = Math.floor(ret.passed_days / ret.year_days * 10000) / 100;
      passWidth.value = ret.passed_days / ret.year_days * 100 + "%";
      remainWidth.value = remain_days.value / ret.year_days * 100 + "%";
    };
    const setHeaderBgStyle = () => {
      let headerBgClass = document.getElementsByClassName("header-bg");
      for (let i = 0; i < headerBgClass.length; i++) {
        let pLeft = passDays.value;
        if (pLeft > 90) {
          pLeft = 90;
        }
        let asideStyle = "position:absolute;left:" + pLeft + "%;";
        headerBgClass[i].setAttribute('style', asideStyle);
      }
    };
    onMounted(() => {
      currTime();
      daysRemainCalc();
      currYear();
      setHeaderBgStyle();
    });
    onUpdated(() => {
      daysRemainCalc();
    });
    return {
      curr_time,
      curr_year,
      currTime,
      remain_days,
      daysRemainCalc,
      passDays,
      passWidth,
      remainWidth
    };
  }
};