/**
 * 存储数据对象，根据key保持唯一
 * @param dataList
 * @param dataItem
 * @param key
 */
export function setDataCommon(dataList, dataItem, key){
    if(dataList.length === 0){
        dataList.push(dataItem);
    }
    else{
        let find = 0;
        for(let i=0;i<dataList.length;i++){
            if(dataList[i][key] === dataItem[key]){
                find = 1;
                dataList[i] = dataItem;
                break;
            }
        }
        if(find === 0){
            dataList.push(dataItem);
        }
    }
}
export function getDataCommon(dataList, compareKey, compareVal){
    let ret = null;
    for(let i=0;i<dataList.length;i++){
        if(dataList[i][compareKey] === compareVal){
            ret = dataList[i];
            break;
        }
    }
    return ret;
}

export function getFilePath(fileName){
    let ret = ''
    ret += process.env.NODE_ENV === 'production' ?  '/bstMesServer' : '/api';
    ret += '/file/'+fileName;
    return ret;

}


//计算两数相加的结果
//0+0=5;
export function num1to9(first, second){
    var ret = 0;
    if(first == 0 && second == 0){
        ret = 5;
    }
    else{
        ret = first+second;
    }
    if(ret > 9){
        ret = ret-9;
    }
    return ret;
}
export function get_one_num(val){
    var ret_obj = {};
    if(val.toString().length == 2){
        ret_obj.first = parseInt(val.toString().substr(0, 1), 10);
        ret_obj.second = parseInt(val.toString().substr(1, 1), 10)
    }
    else{
        ret_obj.first = 0;
        ret_obj.second = parseInt(val, 10);
    }
    return ret_obj;
}
export function get_num(ab_str, cd_str, ef_str, gh_str){
    var ret_obj = {a:0,b:1,c:0,d:6,e:1,f:9,g:8,h:6};
    //var ab_str = $("#AB").val();
    var ab_val = ab_str ? ab_str : 0;
    var ab_obj = get_one_num(ab_val);
    ret_obj.a = ab_obj.first;
    ret_obj.b = ab_obj.second;

    //var cd_str = $("#CD").val();
    var cd_val = cd_str ? cd_str : 0;
    var cd_obj = get_one_num(cd_val);
    ret_obj.c = cd_obj.first;
    ret_obj.d = cd_obj.second;

    //var ef_str = $("#EF").val();
    var ef_val = ef_str ? ef_str : 0;
    var ef_obj = get_one_num(ef_val);
    ret_obj.e = ef_obj.first;
    ret_obj.f = ef_obj.second;

    //var gh_str = $("#GH").val();
    var gh_val = gh_str ? gh_str : 0;
    var gh_obj = get_one_num(gh_val);
    ret_obj.g = gh_obj.first;
    ret_obj.h = gh_obj.second;
    return ret_obj;
}
//验证输入是否正确, 返回错误类型
//校验正确的年,月,日 TODO
export function correct_input_num(num){
    var ret = 0; //正确的
    //长度最多为2位
    if(num.length > 2){
        ret = 1; //长度错误
    }
    //是数字
    if(isNaN(num)){
        ret = 2; //不是数字
    }
    return ret;
}
//根据错误类型返回正确的数字
export function error_num_handling(num, error_type){
    var correct_ret = num;
    if(error_type === 1){
        correct_ret = num.toString().substr(0, 2);
    }
    else if(error_type === 2){
        correct_ret = parseInt(num, 10);
    }
    return correct_ret;
}
//规范输入
export function normalize_num(num){
    var ret_num;
    var check_ret = correct_input_num(num);
    if(check_ret === 0){
        ret_num = num;
    }
    else{
        ret_num = error_num_handling(num, check_ret);
    }
    return ret_num;
}