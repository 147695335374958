import { toRaw, ref, onMounted } from "vue";
import { crawlProfileByName } from "@/networks/crawl/crawl";
import { getCates } from "@/networks/cates/cates";
import { ElNotification } from 'element-plus';
export default {
  setup() {
    const value = ref([]);
    let cateId = -1;
    const names = ref([]);
    const startIndex = ref(1);
    const endIndex = ref(2);
    const showProps = {
      expandTrigger: 'hover',
      label: 'cateName',
      value: 'cateId'
    };
    // const handleFocus = (value)=>{
    //   console.log('handleFocus');
    //   getCates().then(ret=>{
    //     console.log(ret);
    //     cateOptions.value = ret;
    //   })
    // }

    const handleChange = value => {
      console.log('handleCahnge');
      cateId = value[value.length - 1];
    };
    const cateOptions = ref([]);
    const onSubmit = value => {
      let dateObj = new Date();
      let year = dateObj.getFullYear();
      let month = dateObj.getMonth() + 1;
      month = month.toString().length === 1 ? "0" + month : month;
      ;
      let day = dateObj.getDate();
      day = day.toString().length === 1 ? "0" + day : day;
      let pwd = year + '-' + month + '-' + day;
      let person = prompt("Please enter code", "Harry Potter");
      if (person == pwd) {
        console.log(value);
        if (names.value.length == 0) {
          ElNotification({
            title: '请输入名字',
            message: '',
            type: 'error'
          });
          return;
        }
        let reqData = {};
        reqData.cateId = cateId;
        reqData.names = names.value.split("\n");
        reqData.startIndex = startIndex.value + "";
        reqData.endIndex = endIndex.value + "";
        console.log(reqData);
        crawlProfileByName(reqData).then(ret => {
          ElNotification({
            title: '成功添加',
            message: ret,
            type: 'success'
          });
        });
      } else {
        ElNotification({
          title: '口令错误',
          message: '',
          type: 'error'
        });
      }
    };
    onMounted(() => {
      getCates().then(ret => {
        console.log(ret);
        cateOptions.value = ret;
      });
    });
    return {
      value,
      showProps,
      handleChange,
      // handleFocus,
      cateOptions,
      cateId,
      names,
      startIndex,
      endIndex,
      onSubmit
    };
  }
};