/**
 * @copyright (c) 2017, iloveyezi.cn All rights reserved.
 *
 * @file  y_n.html
 * @brief 根据用户输入计算生命密码
 *        参考: <<数字心理学>> 作者:钟缮夤
 *        感谢: 魏福生
 * @author Bert
 * @date 2017-5-6
 */

import { get_num, num1to9 } from "@/assets/js/commonFun";
import { Lunar } from "@/assets/js/lunar";
export default {
  data() {
    return {
      initBirth: '2017-05-06',
      active: 0,
      birthday: {
        day: '01',
        month: '06',
        year_b_2: '19',
        year_a_2: '86'
      },
      lifeCode: {
        I: 1,
        J: 6,
        K: 1,
        L: 5,
        M: 7,
        N: 6,
        O: 4,
        P: 2,
        Q: 1,
        R: 3,
        S: 3,
        T: 9,
        U: 2,
        V: 7,
        W: 4,
        X: 8
      },
      characterArr: [{
        id: 1,
        cNO: '1号人',
        cPositive: '领导力、创造、自信、果断、专注、独立',
        cNegative: '自我、孤独、高傲自大、强势、自我中心'
      }, {
        id: 2,
        cNO: '2号人',
        cPositive: '温和、有耐心、善解人意、善于合作、读心术、分析能力强',
        cNegative: '依赖、比较、易受影响、犹豫不决、优柔寡断'
      }, {
        id: 3,
        cNO: '3号人',
        cPositive: '积极、执行力强、激情、激励、行动、表达、威信',
        cNegative: '冲动、没耐心、坏脾气、不专情、任性'
      }, {
        id: 4,
        cNO: '4号人',
        cPositive: '学习力强、组织能力强、有条理、踏实稳重、善于策划、聪明、务实',
        cNegative: '心直口快、缺乏安全感、单刀直入、犹疑不定、悲观、保守'
      }, {
        id: 5,
        cNO: '5号人',
        cPositive: '目标感强、方向感强、坚持、幽默感、自由自在、豪爽',
        cNegative: '固执、猜疑、冲动、面子、随心所欲'
      }, {
        id: 6,
        cNO: '6号人',
        cPositive: '忠诚、远见、财富、洞察力、完美、发现问题、领悟力、无私奉献',
        cNegative: '浪费、挑剔、自负、易陷入细节'
      }, {
        id: 7,
        cNO: '7号人',
        cPositive: '人缘好、博学、遇贵人、好奇心、好研究、乐于助人',
        cNegative: '不体贴、迟缓、冷淡、多疑'
      }, {
        id: 8,
        cNO: '8号人',
        cPositive: '责任、权威、格局、抗压力、理想抱负、权力、亲善',
        cNegative: '压力、消极、野心、纠结'
      }, {
        id: 9,
        cNO: '9号人',
        cPositive: '机会、正直、智慧、爱冒险、洞察力、察言观色、使命感',
        cNegative: '寂寞、无原则、贪心、不专精、脱离现实'
      }]
    };
  },
  methods: {
    domInit() {
      //初始值
      //me  01 06 19 88
      // var birth = {day:"01", month:"06", year: "1986"};
      //亲: 11 04 19 88;
      //var birth = {day:"11", month:"04", year: "1988"};
      //董: 28 07 19 77;
      // var birth = {day:"28", month:"07", year: "1977"};
      //Steve Jobs
      //var birth = {year: "1955", month:"02", day:"24" };
      //Today
      let dateObj = new Date();
      let year = dateObj.getFullYear();
      let month = dateObj.getMonth() + 1;
      month = month.toString().length === 1 ? "0" + month : month;
      ;
      let day = dateObj.getDate();
      day = day.toString().length === 1 ? "0" + day : day;
      let year_b_2 = year.toString().substr(0, year.toString().length - 2);
      let year_a_2 = year.toString().substr(-2);
      this.initBirth = year + '-' + month + '-' + day;
      console.log(this.initBirth);
      this.birthday.day = day;
      this.birthday.month = month;
      this.birthday.year_b_2 = year_b_2;
      this.birthday.year_a_2 = year_a_2;
      var ret_num = get_num(day, month, year_b_2, year_a_2);
      this.calc_num(ret_num.a, ret_num.b, ret_num.c, ret_num.d, ret_num.e, ret_num.f, ret_num.g, ret_num.h);
    },
    calc_num(a, b, c, d, e, f, g, h) {
      var i = num1to9(a, b);
      var j = num1to9(c, d);
      var k = num1to9(e, f);
      var l = num1to9(g, h);
      var m = num1to9(i, j);
      var n = num1to9(k, l);
      var o = num1to9(m, n);
      var w = num1to9(j, m);
      var x = num1to9(i, m);
      var s = num1to9(x, w);
      var v = num1to9(k, n);
      var u = num1to9(l, n);
      var t = num1to9(v, u);
      var q = num1to9(n, o);
      var p = num1to9(m, o);
      var r = num1to9(q, p);
      this.lifeCode.I = i;
      this.lifeCode.J = j;
      this.lifeCode.K = k;
      this.lifeCode.L = l;
      this.lifeCode.M = m;
      this.lifeCode.N = n;
      this.lifeCode.O = o;
      this.lifeCode.P = p;
      this.lifeCode.Q = q;
      this.lifeCode.R = r;
      this.lifeCode.S = s;
      this.lifeCode.T = t;
      this.lifeCode.U = u;
      this.lifeCode.V = v;
      this.lifeCode.W = w;
      this.lifeCode.X = x;
      this.setMainHl(o);
    },
    setMainHl(main_num) {
      this.active = main_num;
    },
    setInitBirth() {
      if (this.initBirth) {
        //这里可以通过双向绑定的形式获取值
        let birthday_val = this.initBirth;
        let b_v_arr = birthday_val.split("-");

        //此次需要把获取的value写到对应的文本框中
        //var birth = {year: b_v_arr[0], month:b_v_arr[1], day:b_v_arr[2]};
        //init_birthday(birth);
        let day = b_v_arr[2];
        let month = b_v_arr[1];
        let year_b_2 = b_v_arr[0].toString().substr(0, b_v_arr[0].toString().length - 2);
        let year_a_2 = b_v_arr[0].toString().substr(-2);
        this.birthday.day = day;
        this.birthday.month = month;
        this.birthday.year_b_2 = year_b_2;
        this.birthday.year_a_2 = year_a_2;
        var ret_num = get_num(day, month, year_b_2, year_a_2);
        this.calc_num(ret_num.a, ret_num.b, ret_num.c, ret_num.d, ret_num.e, ret_num.f, ret_num.g, ret_num.h);
      }
    },
    toSolr(year, month, day) {
      var solar_arr = Lunar.toSolar(year, month, day);
      if (solar_arr && solar_arr[0]) {
        //从新赋值
        this.birthday.day = solar_arr[2];
        this.birthday.month = solar_arr[1];
        var solar_year = solar_arr[0].toString();
        this.birthday.year_b_2 = solar_year.substr(0, solar_year.length - 2);
        this.birthday.year_a_2 = solar_year.substr(-2);
        var ret_num = get_num(this.birthday.day, this.birthday.month, this.birthday.year_b_2, this.birthday.year_a_2);
        this.calc_num(ret_num.a, ret_num.b, ret_num.c, ret_num.d, ret_num.e, ret_num.f, ret_num.g, ret_num.h);
      } else {
        alert('转换错误,请您先输入阳历生日!');
      }
    },
    changeToSolar(event) {
      let clickDom = event.currentTarget; // currentTarget 获取绑定点击事件的节点
      let previous = clickDom.previousElementSibling; // previousElementSibling获取绑定事件节点的前一个节点
      //alert(previous.value);
      //此处调用转阳历的方法，显示出阳历，用户输入的是农历，转为阳历
      //alert(this.$refs.birthday.value)
      let b_v = this.$refs.birthday.value;
      let b_v_arr = b_v.split("-");
      let year = b_v_arr[0];
      let month = b_v_arr[1];
      let day = b_v_arr[2];
      this.toSolr(year, month, day);
    },
    turnToSolar(event) {
      this.toSolr(this.birthday.year_b_2.toString() + this.birthday.year_a_2, this.birthday.month, this.birthday.day);
    },
    ABKeyup(event) {
      var ret_num = get_num(this.birthday.day, this.birthday.month, this.birthday.year_b_2, this.birthday.year_a_2);
      this.calc_num(ret_num.a, ret_num.b, ret_num.c, ret_num.d, ret_num.e, ret_num.f, ret_num.g, ret_num.h);
    },
    CDKeyup(event) {
      var ret_num = get_num(this.birthday.day, this.birthday.month, this.birthday.year_b_2, this.birthday.year_a_2);
      this.calc_num(ret_num.a, ret_num.b, ret_num.c, ret_num.d, ret_num.e, ret_num.f, ret_num.g, ret_num.h);
    },
    EFKeyup(event) {
      var ret_num = get_num(this.birthday.day, this.birthday.month, this.birthday.year_b_2, this.birthday.year_a_2);
      this.calc_num(ret_num.a, ret_num.b, ret_num.c, ret_num.d, ret_num.e, ret_num.f, ret_num.g, ret_num.h);
    },
    GHKeyup(event) {
      var ret_num = get_num(this.birthday.day, this.birthday.month, this.birthday.year_b_2, this.birthday.year_a_2);
      this.calc_num(ret_num.a, ret_num.b, ret_num.c, ret_num.d, ret_num.e, ret_num.f, ret_num.g, ret_num.h);
    },
    handleChange() {}
  },
  onLoad() {
    //自动加载domInit方法
    this.domInit();
  }
};