//此处的function是同步操作的。其他的都跟actions上面一样。
const mutations = {
    setTheme:(state, theme)=>{
        state.nowTheme = theme + '-theme';
    },
    //唯一可以修改state值的地方
    setLogout:(state)=>{
        state.userInfo = {};
        window.localStorage.clear();
    },
    setLogoff:(state)=>{
        state.userInfo = {};
        window.localStorage.removeItem("userInfo");
    },
    setUserInfo:(state, payload)=>{
        //登录时初始化localStorage, 进行一次清除。
        // window.localStorage.clear();
        //登录时不清除, 进行覆盖
        //将token保存到本地window.localStorage setItem(key,value) getItem(key)
        if(payload){
            state.userInfo = payload;
            window.localStorage.setItem('userInfo', JSON.stringify(payload));
        }
    },
    setUsers:(state, payload)=>{
        state.userList = payload;
    },
}

export default mutations;