import { ref, onMounted } from "vue";
import { ElNotification } from 'element-plus';
export default {
  setup() {
    const birthdayInput = ref("1988-06-01");
    const onSubmit = () => {
      if (birthdayInput.value.length == 0) {
        ElNotification({
          title: '请输入问题',
          message: '',
          type: 'error'
        });
        return;
      } else {
        genChart();
      }
    };
    // 获取指定日期的儒略日
    function getJulianDay(year, month, day, hour) {
      if (month < 3) {
        year--;
        month += 12;
      }
      const a = Math.floor(year / 100);
      const b = 2 - a + Math.floor(a / 4);
      const jd = Math.floor(365.25 * (year + 4716)) + Math.floor(30.6001 * (month + 1)) + day + b - 1524.5 + hour / 24;
      return jd;
    }

    // 获取指定经度和时区的恒星时角
    function getSiderealTime(jd, longitude, timezone) {
      const t = (jd - 2451545) / 36525;
      const gmst = 280.46061837 + 360.98564736629 * (jd - 2451545) + 0.000387933 * t * t - t * t * t / 38710000;
      const lst = gmst + longitude / 15 + timezone;
      return lst >= 0 ? lst % 360 : lst % 360 + 360;
    }
    function genChart() {
      // 获取 canvas 绘图上下文
      // const canvas = this.$refs.canvas;
      let canvasId = document.getElementById("canvasId");
      const canvasRef = ref(null);
      console.log(canvasId);
      console.log(canvasRef.value);
      // const context = canvasRef.value.getContext('2d');
      const context = canvasId.getContext('2d');

      // 增加抗锯齿属性
      context.imageSmoothingEnabled = true;
      context.imageSmoothingQuality = 'high';

      // 设置绘图参数
      const canvasWidth = canvasId.width;
      const canvasHeight = canvasId.height;
      const radius = Math.min(canvasWidth, canvasHeight) / 2 - 5;
      const center = {
        x: canvasWidth / 2,
        y: canvasHeight / 2
      };
      const lineWidth = 1;
      const fontColor = '#333';
      const fontSize = 24;

      // 绘制背景
      context.fillStyle = '#fff';
      context.fillRect(0, 0, canvasWidth, canvasHeight);

      // 绘制天盘
      context.lineWidth = lineWidth;
      context.strokeStyle = '#333';
      context.beginPath();
      context.arc(center.x, center.y, radius, 0, 2 * Math.PI);
      context.stroke();

      // 绘制星座盘
      const constellationOuterRadius = radius - lineWidth - fontSize * 2;
      const constellationInnerRadius = radius - lineWidth - fontSize * 6;
      const constellationSize = 12;
      const constellationOffset = Math.PI / 2;
      for (let i = 0; i < constellationSize; i++) {
        const angle = i / constellationSize * 2 * Math.PI + constellationOffset;
        const x1 = center.x + constellationOuterRadius * Math.cos(angle);
        const y1 = center.y + constellationOuterRadius * Math.sin(angle);
        const x2 = center.x + constellationInnerRadius * Math.cos(angle);
        const y2 = center.y + constellationInnerRadius * Math.sin(angle);
        const text = String.fromCharCode(9800 + i);
        context.fillText(text, x2 - fontSize / 2, y2 + fontSize / 2);
        context.beginPath();
        context.moveTo(x1, y1);
        context.lineTo(x2, y2);
        context.stroke();
      }
      // 绘制行星
      const planetPositions = [{
        name: 'Sun',
        angle: 0
      }, {
        name: 'Moon',
        angle: 60
      }, {
        name: 'Mercury',
        angle: 120
      },
      //水星
      {
        name: 'Venus',
        angle: 180
      },
      //金星
      {
        name: 'Mars',
        angle: 240
      },
      //火星
      {
        name: 'Jupiter',
        angle: 300
      } //木星
      ];

      const planetRadius = radius - lineWidth - fontSize * 4;
      for (let i = 0; i < planetPositions.length; i++) {
        const planet = planetPositions[i];
        const planetAngle = planet.angle * Math.PI / 180;
        const x = center.x + planetRadius * Math.sin(planetAngle);
        const y = center.y - planetRadius * Math.cos(planetAngle);
        const text = planet.name.substring(0, 3);
        context.fillText(text, x - fontSize / 2, y + fontSize / 2);
        context.beginPath();
        context.arc(x, y, 5, 0, 2 * Math.PI);
        context.fill();
      }

      // 绘制宫位
      const houseOuterRadius = radius - lineWidth - fontSize * 8;
      const houseInnerRadius = radius - lineWidth - fontSize * 14;
      const houseSize = 24;
      const houseOffset = Math.PI / 2;
      for (let i = 0; i < houseSize; i++) {
        const angle = i / houseSize * 2 * Math.PI + houseOffset;
        const x1 = center.x + houseOuterRadius * Math.cos(angle);
        const y1 = center.y + houseOuterRadius * Math.sin(angle);
        const x2 = center.x + houseInnerRadius * Math.cos(angle);
        const y2 = center.y + houseInnerRadius * Math.sin(angle);
        const text = (i + 1).toString();
        context.fillText(text, x2 - fontSize / 2, y2 + fontSize / 2);
        context.beginPath();
        context.moveTo(x1, y1);
        context.lineTo(x2, y2);
        context.stroke();
      }

      // 绘制相位
      const phaseRadius = radius - lineWidth - fontSize * 4;
      const phaseSize = 12;
      const phaseOffset = Math.PI / 2;
      for (let i = 0; i < phaseSize; i++) {
        const angle = i / phaseSize * 2 * Math.PI + phaseOffset;
        const x = center.x + phaseRadius * Math.cos(angle);
        const y = center.y + phaseRadius * Math.sin(angle);
        const text = ` ${i}° `;
        context.fillText(text, x - fontSize / 2, y + fontSize / 2);
      }

      // 绘制相冲线
      const oppositionRadius = radius - lineWidth - fontSize * 6;
      const oppositionSize = 6;
      const oppositionOffset = Math.PI / 2;
      for (let i = 0; i < oppositionSize; i++) {
        const angle = i / oppositionSize * Math.PI + oppositionOffset;
        const x1 = center.x + oppositionRadius * Math.cos(angle);
        const y1 = center.y + oppositionRadius * Math.sin(angle);
        const x2 = center.x + oppositionRadius * Math.cos(angle + Math.PI);
        const y2 = center.y + oppositionRadius * Math.sin(angle + Math.PI);
        context.beginPath();
        context.moveTo(x1, y1);
        context.lineTo(x2, y2);
        context.stroke();
      }

      // 绘制指针
      const birthday = new Date(birthdayInput.value);
      const year = birthday.getFullYear();
      const month = birthday.getMonth() + 1;
      const day = birthday.getDate();
      const birthHour = 0; // 假设出生时间为 0 时刻
      const jd = getJulianDay(year, month, day, birthHour);
      const pointerRadius = radius - lineWidth - fontSize * 8;
      const pointerAngle = getSiderealTime(jd, 120, 0) * Math.PI / 180;
      const pointerX = center.x + pointerRadius * Math.sin(pointerAngle);
      const pointerY = center.y - pointerRadius * Math.cos(pointerAngle);
      context.beginPath();
      context.lineWidth = 4;
      context.strokeStyle = 'red';
      context.moveTo(center.x, center.y);
      context.lineTo(pointerX, pointerY);
      context.stroke();

      // 绘制标题
      context.fillStyle = fontColor;
      context.font = `${fontSize * 2}px sans-serif`;
      const titleText = '本命星盘';
      const titleX = center.x - context.measureText(titleText).width / 2;
      const titleY = center.y - radius - lineWidth - fontSize * 3;
      context.fillText(titleText, titleX, titleY);

      // 绘制底部注释
      context.font = `${fontSize}px sans-serif`;
      const commentText = '生成于 ' + new Date().toLocaleString();
      const commentX = center.x - context.measureText(commentText).width / 2;
      const commentY = center.y + radius + lineWidth + fontSize * 2;
      context.fillText(commentText, commentX, commentY);
    }
    onMounted(() => {});
    return {
      birthdayInput,
      onSubmit
    };
  }
};