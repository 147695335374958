import {axiosFetch} from '../axiosFetch'

export function crawlProfileByName(data){
    return axiosFetch({
        url:'/crawl/crawlProfileByName',
        method:'post',
        headers:{'Content-Type':'application/json;charset=utf-8'},
        data:JSON.stringify(data)
    })
}
