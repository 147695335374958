import axios from 'axios'
import { ElNotification } from 'element-plus'
import router from "../router";
import store from '../store'

export function axiosFetch(config){
    let loading = false;
    console.log(process.env.NODE_ENV);
    const instance = axios.create({
        // baseURL:'/api',
        /**
         * 使用idea启动时为开发环境，此时存在跨域问题，访问api会devServer会处理访问服务器
         * 部署后未生产环境访问bstMesServer,同时因为部署到同一目录下不存在跨域问题。
         * 如果是线上环境，项目不用走vue的proxy代理。 baseURL为 http://xxxxxxxx:8000/，当使用接口时，
         * 接口前会自动添加http://xxxxxxxx:8000/
         * 如果是开发环境，项目会走`devServer`代理
         */
        baseURL: process.env.NODE_ENV === 'production' ?  '/svyServer' : '/api',
        timeout:5000
    });
//请求拦截
    instance.interceptors.request.use(config=>{
        //开始loading
        // Toast.loading({
        //     message: '加载中...',
        //     forbidClick: true,
        // });
        // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
        // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
        const token = store.getters.getUserInfo && store.getters.getUserInfo.token;
        if(token){
            config.headers.token = token;
        }
        return config;
    }, error => {
        console.log("请求错误：", error);
        return Promise.error(error);
    });


//响应拦截
    instance.interceptors.response.use(response=>{
        // loading.close()
        console.log(response.request.responseURL+"返回结果：", response);
        if(response.data.code === "00000"){
            return response.data.data;
        }
        else{
            switch (response.data.code){
                case "B0105":
                //无效签名!
                case "B0201":
                //token过期
                case "B0202":
                //TOKEN算法不一致
                case "B0203":
                    //TOKEN无效
                    ElNotification({
                        title: '错误',
                        message: response.data.msg,
                        type: 'error',
                    })
                    store.commit('setLogoff');
                    router.push({path:'/login'});
                    break;
                //未登录
                case "B0101":
                //请先登录
                case "B0102":
                //用户登录失败
                case "B0103":
                //用户已被禁用
                case "B0104":
                //用户密码错误
                case "B0300":
                //脚本服务未定义
                case "B0301":
                //脚本服务连接失败
                case "C0001":
                    //请求异常
                    ElNotification({
                        title: '错误',
                        message: response.data.msg,
                        type: 'error',
                    })

                    break;
                default:
                    console.log("发生错误：", response.data);
                    break;
            }
        }
   }, error => {
        // loading.close()
        console.log("发生错误：", error);
        ElNotification({
            title: '错误',
            message: '请求失败',
            type: 'error',
        })
        let {
            response
        } = error;
        if(response){
            //服务器有结果返回
            switch (response.status){
                case 401: //未登录
                    ElNotification({
                        title: '错误',
                        message: '请先登录',
                        type: 'error',
                    })

                    router.push({path:'/login'});
                    break;
                case 403: //token过期
                    ElNotification({
                        title: '错误',
                        message: 'Token过期！',
                        type: 'error',
                    })

                    break;
                case 404: //找不到页面
                    ElNotification({
                        title: '错误',
                        message: '找不到页面！',
                        type: 'error',
                    })
                    break;
                case 500: //Internal Server Error
                    ElNotification({
                        title: '错误',
                        message: '服务器错误！',
                        type: 'error',
                    })
                    return;
                default:
                    break;
            }
        }
        else{
            //服务器没有结果返回
            if(!window.navigator.onLine){
                //断网处理：可以跳转到断网页面
                ElNotification({
                    title: '错误',
                    message: '断网了！',
                    type: 'error',
                })

                return;
            }
            return Promise.reject(error);
        }
   })
    return instance(config);
}

export function axiosPost(data){
    return axiosFetch({
        url: data.url,
        method:'post',
        headers:{'Content-Type':'application/json;charset=utf-8'},
        data:JSON.stringify(data.data)
    })
}
export function axiosGet(data){
    return axiosFetch({
        url: data.url,
        method:'get'
    })
}