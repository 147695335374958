// @ is an alias to /src
import { onMounted, ref } from "vue";
import { Document, Menu as IconMenu, Location, Setting } from '@element-plus/icons-vue';
import LifeCode from "@/components/LifeCode/LifeCode";
import LifeSpan from "@/components/LifeSpan/LifeSpan";
import DeepThinking from "@/components/DeepThinking/DeepThinking";
import AddProfile from "@/components/LifeSpan/AddProfile";
import CodeCalculator from "@/components/LifeCode/CodeCalculator";
import LifeChat from "@/components/LifeSpan/LifeChat";
import BirthChart from "@/components/LifeSpan/BirthChart";
export default {
  name: 'Home',
  components: {
    CodeCalculator,
    DeepThinking,
    LifeSpan,
    LifeCode,
    Document,
    IconMenu,
    Location,
    AddProfile,
    LifeChat,
    BirthChart
  },
  setup() {
    const drawerMenu = ref(false);
    const active = ref('1');
    const handleSelect = (index, indexPath, item) => {
      console.log("select", index, indexPath, item);
      active.value = index;
      drawerMenu.value = false;
    };
    const handleIconMenu = () => {
      drawerMenu.value = !drawerMenu.value;
    };
    const setElAsideStyle = () => {
      let viewHeight = window.innerHeight;
      let viewWidth = window.innerWidth;
      let elAsideClass = document.getElementsByClassName("el-aside");
      let homeIconClass = document.getElementsByClassName("home-icon-menu");
      for (let i = 0; i < elAsideClass.length; i++) {
        if (viewHeight > viewWidth) {
          let asideStyle = "display: none;";
          elAsideClass[i].setAttribute('style', asideStyle);
          drawerMenu.value = true;
          let homeIconStyle = "display: block;";
          homeIconClass[0].setAttribute('style', homeIconStyle);
        } else {
          let asideStyle = "display: block;";
          elAsideClass[i].setAttribute('style', asideStyle);
          drawerMenu.value = false;
          let homeIconStyle = "display: none;";
          homeIconClass[0].setAttribute('style', homeIconStyle);
        }
      }
    };
    onMounted(() => {
      setElAsideStyle();
      // 监听浏览器高度变化
      window.onresize = () => {
        setElAsideStyle();
      };
    });
    return {
      active,
      handleSelect,
      drawerMenu,
      handleIconMenu
    };
  }
};