import { ref, onMounted } from "vue";
import { ElNotification } from 'element-plus';
import { axiosPost } from "@/networks/axiosFetch";
export default {
  setup() {
    const question = ref("");
    const answer = ref("");
    const onSubmit = value => {
      let dateObj = new Date();
      let year = dateObj.getFullYear();
      let month = dateObj.getMonth() + 1;
      month = month.toString().length === 1 ? "0" + month : month;
      ;
      let day = dateObj.getDate();
      day = day.toString().length === 1 ? "0" + day : day;
      let pwd = year + '-' + month + '-' + day;
      let person = prompt("Please enter code", "Harry Potter");
      if (person == pwd) {
        console.log(value);
        if (question.value.length == 0) {
          ElNotification({
            title: '请输入问题',
            message: '',
            type: 'error'
          });
          return;
        }
        let reqData = {};
        reqData.question = question.value;
        console.log(reqData);
        axiosPost({
          url: '/openai/generateText',
          data: reqData
        }).then(ret => {
          console.log(ret);
          answer.value = ret.answer;
        });
      } else {
        ElNotification({
          title: '口令错误',
          message: '',
          type: 'error'
        });
      }
    };
    onMounted(() => {});
    return {
      question,
      answer,
      onSubmit
    };
  }
};