import {axiosFetch} from '../axiosFetch'

export function getCates(data){
    return axiosFetch({
        url:'/cate/getCates',
        method:'post',
        headers:{'Content-Type':'application/json;charset=utf-8'},
        data:JSON.stringify(data)
    })
}
