/**
 * token，需要请求服务器的API，此处进行处理
 * 这里处理完成后，通过commit()到mutations
 * @type {{}}
 */
import {axiosFetch, axiosGet} from "../networks/axiosFetch";
import {getUsers, login} from "@/networks/core/user";
const actions = {
    requestUserInfo({state, commit, getters}, payload){
        return new Promise((resolve,reject)=>{
            login(payload).then(ret=>{
                commit('setUserInfo', ret);
                resolve(ret);
            })
        })
    },
    requestUsers({state, commit, getters}){
        return new Promise((resolve,reject)=>{
            if(state.userList.length > 0){
                resolve(state.userList);
            }
            else{
                getUsers().then(ret => {
                    commit('setUsers', ret);
                    resolve(ret);
                })
            }
        })
    }
}
export default actions;